'use strict';

const PREV_ARROW = '<svg class="icon right-arrow svg-right-arrow-dims flipped slick-prev " role="button"><use xlink:href="#right-arrow-circle"/></svg>';
const NEXT_ARROW = '<svg class="icon right-arrow svg-right-arrow-dims slick-next " role="button"><use xlink:href="#right-arrow-circle"/></svg>';

function initSliders() {
    /* looking for marketing drawer slider, look in animation.js */

    /**
     * @description Slider for header marketing ribbon
     */
    var thumbContainerMarketingRibbon = $('.header-ribbon.secondary'),
        thumbCountMarketingRibbon = $(thumbContainerMarketingRibbon).find('li').length;
    if (thumbCountMarketingRibbon > 1) {
        $('.header-ribbon.secondary .this-slider').slick({
            autoplay: true,
            pauseOnHover: true,
            slidesToShow: 1,
            arrows: true,
            infinite: true,
            mobileFirst: true,
            adaptiveHeight: false,
            prevArrow: $('.mr-prev'),
            nextArrow: $('.mr-next')
        });
    }

    /**
     * @description Slider for manual products one row mobile
     */
    var thumbContainerManProduct = $('.collection-product-carousel-items'),
        thumbCountManProduct = $(thumbContainerManProduct).find('li').length;
    if (thumbCountManProduct > 1) {
        $('.collection-product-carousel-items').slick({
            autoplay: false,
            slidesToShow: 2,
            dots: false,
            arrows: true,
            infinite: false,
            mobileFirst: true,
            adaptiveHeight: false,
            lazyLoad: 'ondemand',
            prevArrow: PREV_ARROW,
            nextArrow: NEXT_ARROW,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4
                    }
                }
            ]
        });
    }

    /**
     * @description Slider for manual products two row mobile
     */
    var thumbContainerManProductTwoRow = $('.collection-product-carousel-two-row-items'),
        thumbCountManProductTwoRow = $(thumbContainerManProductTwoRow).find('li').length;
    if (thumbCountManProductTwoRow > 1) {
        $('.collection-product-carousel-two-row-items').slick({
            autoplay: false,
            slidesToShow: 2,
            rows: 2,
            dots: false,
            arrows: true,
            infinite: true,
            mobileFirst: true,
            lazyLoad: 'ondemand',
            adaptiveHeight: false,
            prevArrow: PREV_ARROW,
            nextArrow: NEXT_ARROW,
            responsive: [
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 4,
                        rows: 1
                    }
                }
            ]
        });
    }

    /**
     * @description Slider for 1m 2d slides.
     */
    var thumbContainerDouble = $('.slider.double'),
        thumbCountDouble = $(thumbContainerDouble).find('li').length;
    if (thumbCountDouble > 1) {
        $('.slider.double .this-slider').slick({
            autoplay: false,
            dots: false,
            slidesToShow: 1,
            arrows: true,
            infinite: true,
            mobileFirst: true,
            lazyLoad: 'ondemand',
            prevArrow: PREV_ARROW,
            nextArrow: NEXT_ARROW,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        });
    }

    /**
     * @description Slider for 4 slides generic with peekaboo.
     */
    var thumbContainerGen = $('.slider-peek.generic'),
        thumbCountGen = $(thumbContainerGen).find('li').length;
    if (thumbCountGen > 1) {
        $('.component.slider-peek.generic .this-slider').slick({
            autoplay: false,
            slidesToShow: 1,
            arrows: true,
            infinite: true,
            mobileFirst: true,
            slidesToScroll: 1,
            centerMode: true,
            initialSlide: 0,
            dots: false,
            prevArrow: PREV_ARROW,
            nextArrow: NEXT_ARROW,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3,
                        mobileFirst: true,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        centerMode: false
                    }
                }
            ]
        });
    }

    /**
     * @description Slider for collection 4 slides mini with peekaboo.
     */
    var thumbContainerMini = $('.slider-peek.circlenav.mini'),
        thumbCountMini = $(thumbContainerMini).find('li').length;
    if (thumbCountMini > 1) {
        $('.component.slider-peek.circlenav.mini .this-slider').slick({
            autoplay: false,
            slidesToShow: 2.5,
            arrows: false,
            infinite: false,
            mobileFirst: true,
            slidesToScroll: 2,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        autoplay: false,
                        slidesToShow: 3.5,
                        arrows: false,
                        mobileFirst: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        dots: false,
                        arrows: true,
                        infinite: true,
                        centermode: true,
                        slidesToShow: 8,
                        slidesToScroll: 1,
                        lazyLoad: 'ondemand',
                        prevArrow: PREV_ARROW,
                        nextArrow: NEXT_ARROW
                    }
                }
            ]
        });
    }

    /**
     * @description Slider for collection 4 slides and two rows with peekaboo.
     */
    var thumbContainerLarge = $('.slider-peek.circlenav.large'),
        thumbCountLarge = $(thumbContainerLarge).find('li').length;
    if (thumbCountLarge > 1) {
        $('.component.slider-peek.circlenav.large .this-slider').slick({
            rows: 2,
            autoplay: false,
            slidesToShow: 2.5,
            arrows: false,
            infinite: false,
            mobileFirst: true,
            slidesToScroll: 2,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        autoplay: false,
                        slidesToShow: 3.5,
                        arrows: false,
                        infinite: false,
                        mobileFirst: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        rows: 1,
                        dots: false,
                        arrows: true,
                        infinite: true,
                        centermode: true,
                        slidesToShow: 8,
                        slidesToScroll: 1,
                        lazyLoad: 'ondemand',
                        prevArrow: PREV_ARROW,
                        nextArrow: NEXT_ARROW
                    }
                }
            ]
        });
    }

    /**
     * @description Slider for collection 4 slides and two rows with peekaboo.
     */
    var thumbContainerXlarge = $('.slider-peek.circlenav.xlarge'),
        thumbCountXlarge = $(thumbContainerXlarge).find('li').length;
    if (thumbCountXlarge > 1) {
        $('.component.slider-peek.circlenav.xlarge .this-slider').slick({
            rows: 4,
            autoplay: false,
            slidesToShow: 2.5,
            arrows: false,
            infinite: false,
            mobileFirst: true,
            slidesToScroll: 2,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        autoplay: false,
                        slidesToShow: 3.5,
                        arrows: false,
                        infinite: false,
                        mobileFirst: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        rows: 1,
                        dots: false,
                        arrows: true,
                        infinite: true,
                        centermode: true,
                        slidesToShow: 8,
                        slidesToScroll: 1,
                        lazyLoad: 'ondemand',
                        prevArrow: PREV_ARROW,
                        nextArrow: NEXT_ARROW
                    }
                }
            ]
        });
    }
}

/**
 * @description Slider for recommenders requires timer
 */
var einsteinTimer = setInterval(function () { initEin(); }, 1000);

var initEin = function () {
    var thumbContainerEinstein = $('.einstein-carousel'),
        thumbCountEinstein = $(thumbContainerEinstein).find('li').length;
    if (thumbCountEinstein > 1) {
        clearInterval(einsteinTimer);
        $('.einstein-carousel').slick({
            autoplay: false,
            slidesToShow: 2,
            arrows: true,
            dots: false,
            infinite: true,
            mobileFirst: true,
            adaptiveHeight: false,
            lazyLoad: 'ondemand',
            prevArrow: PREV_ARROW,
            nextArrow: NEXT_ARROW,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4
                    }
                }
            ]
        });
    }
};

var sliders = {
    init: function () {
        initSliders();
    }
};

module.exports = sliders;
